<template>
  <v-list-item>
    <v-list-item-content v-if="$vuetify.breakpoint.xs">
      <v-list-item-subtitle
        >{{ title }}
        <span v-if="showVisibility"
          >(sichtbar für <LookupValue :value="visibility" />)</span
        ></v-list-item-subtitle
      >
      <slot></slot>
    </v-list-item-content>
    <v-list-item-content v-else>
      <v-row v-bind="$attrs" class="align-center">
        <v-col cols="4" class="text-right text--secondary"
          ><v-list-item-subtitle
            >{{ title }}
            <span v-if="showVisibility"
              ><br />(sichtbar für <LookupValue :value="visibility" />)</span
            ></v-list-item-subtitle
          ></v-col
        >
        <v-col align="left"><slot></slot> </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import LookupValue from "common/components/LookupValue.vue";

export default {
  name: "RowItem",
  components: { LookupValue },
  props: {
    showVisibility: { type: Boolean, default: false },
    title: { type: String },
    visibility: { type: Object, default: null },
  },
};
</script>
